import type { ReactNode } from "react";
import { cloneElement } from "react";

interface WrapperProps {
  condition: any;
  wrapper: (children: ReactNode | undefined) => JSX.Element;
  children: ReactNode;
}

const Wrapper = ({ condition, wrapper, children }: WrapperProps) =>
  condition ? cloneElement(wrapper(children!)) : <>{children}</>;

export default Wrapper;
